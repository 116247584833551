import React, { useState } from "react";
import {
  successNotification,
  errorNotification,
} from "../../../../utils/notifications";
import API from "../../../../utils/api";
import { trackPromise } from "react-promise-tracker";

const RejectPopUp = ({ closeModal, data, updatedData }) => {
  const [formValue, setFormValue] = useState({
    rejectReason: "",
    description: "",
  });

  const refresh = () => {
    updatedData();
  };

  const finalObj = {
    status: "Rejected By Finance",
    BOMID: data.BOMID,
    POID: data.POID,
    reason: formValue.rejectReason,
    ...(formValue.rejectReason === "Other" && {
      Description: formValue.description,
    }),
  };

  const submitHandler = async () => {
    if(formValue.rejectReason){
      const resp = await trackPromise(
        API.put(`finance/api/v1/newrequest/actionstatus`, finalObj)
      );
  
      const { success, message } = resp;
  
      if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh();
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    }
   else{
    errorNotification("Please select a reason", { autoclose: 2000 });
   }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Reject BOM Request</h3>
        </div>
        <div className="body">
          <label
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "fit-content",
              }}
            >
              Reactivate Reason<span style={{ color: "red" }}>*</span>
            </label>

            <select
              name="rejectReason"
              style={{ marginTop: "-7px" }}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Reject Reason
              </option>
              <option value="Vendor Blacklisted">Vendor Blacklisted</option>
              <option value="In-correct BOM ID">In-correct BOM ID</option>
              <option value="In-correct delivery location">
                In-correct delivery location
              </option>
              <option value="Other">Other</option>
            </select>
          </label>
          {formValue.rejectReason === "Other" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Reject Reason"
                autoComplete="off"
              />
            </label>
          ) : null}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectPopUp;
