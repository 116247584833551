import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification,errorNotification } from "../../../utils/notifications"


const ComponentHold = ({closeModal, rowData, updatedData,setOpen}) => {
  const [formValue, setFormValue] = useState({
    HoldReason: "",
    description: "",
  });

  const refresh = ()=>{
    updatedData()
  }

  const editDetails  = {
    requestID:rowData.requestID,
    status:"On Hold",
    reason: formValue.HoldReason,
    ...(formValue.HoldReason == "Other" ? {reasonDescription:formValue.description}:{reasonDescription:""})
   };

  const submitHandler = async () => {
    if(formValue?.HoldReason){
      const resp = await trackPromise(API.post(`qc-module/api/v1/vehicle/newrequest/status`, editDetails));
    
      const { success,message} = resp;
      
      if (success) {
          closeModal(false);
          setOpen(false)
          successNotification(message, { autoClose: 3000 });
          refresh()
      } else {
          errorNotification(message, { autoclose: 2000 });
      }
    }
    else{
      errorNotification("Please select a reason", { autoclose: 2000 });
    }
    
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Hold</h3>
         
        </div>

        <div className="body">
          <label>
            <select
              name="HoldReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Hold Reason
              </option>
              <option value="Testing Equipment not ready">
              Testing Equipment not ready
              </option>
              <option value="Electricity Issue">
              Electricity Issue
              </option>
              <option value="Other">Other</option>
            </select><br />
          </label>

          {formValue.HoldReason === "Other" ? 
          <label className="lgInput">
            Description
            <input
              type="text"
              style= {{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Description"
              autoComplete="off"
            />
          </label> : ""}

        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={()=>{submitHandler()}}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComponentHold;
